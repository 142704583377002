export default {
  COMMON: "common",
  g8777_8698: "8777_8698",
  user_photo: "user_photo",
  predefined: "predefined",
  original_context: "original_context",
  original_context_BG: "original_context_BG",
  face: "face",
  skin: "skin",
  head: "head",
};
