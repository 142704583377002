import {combo} from "./helpers";
import Creative from "../Creative";
import groups from "./groups";

export function getProcessingGroups() {
  return [
    groups.g8777_8698,
  ];
}

export function getCreativesConfigs(
  fileIndex,
  seeds,
  prompt,
  negativePrompt,
  strengths,
  faceTemplateChain,
  pixel_perfects,
  control_weights,
  control_modes,
  inpainting_mask_invert
) {
  const results = [];
  const positions = [
    {
      group: groups.user_photo,
      template_name: 8779,
      canvas_template_name: 8663,
      mask_template_name: 8664,
      pose_template_name: 8663,
    },
    {
      group: groups.predefined,
      template_name: 8739,
      canvas_template_name: 8781,
      mask_template_name: 8789,
      pose_template_name: 8788
    },
    {
      group: groups.original_context,
      template_name: 8779,
      canvas_template_name: 8659,
      mask_template_name: 8790,
      pose_template_name: 8663
    },
    {
      group: groups.original_context_BG,
      template_name: 8779,
      canvas_template_name: 8659,
      mask_template_name: 8790,
      pose_template_name: 8663
    },
  ];

  positions.forEach((position) => {
    seeds.forEach((seed) => {
      strengths.forEach((strength) => {
        const templateParams = {
          canvas_template_name: position.canvas_template_name,
          mask_template_name: position.mask_template_name,
          pose_template_name: position.pose_template_name,
          seeds: seed,
          prompt,
          negative_prompt: negativePrompt,
          strength,
        };

        if (position.group === groups.predefined) {
          templateParams.ctrlnet_modules = "none";
          templateParams.sampler_name = "DPM$$ 2M Karras";
          templateParams.guessmode = true;
          templateParams.inpainting_fill = 1;
          templateParams.cfg_scale = 15;
          templateParams.mask_blur = 0;
          templateParams.num_steps = 30;
        } else if (position.group === groups.original_context || position.group === groups.original_context_BG) {
          templateParams.ctrlnet_modules = "depth_midas, openpose_full";
          templateParams.pixel_perfects = "true, true";
          templateParams.control_weights = control_weights;
          templateParams.control_modes = control_modes;
          templateParams.mask_blur = 4;
          templateParams.inpainting_mask_invert = 0;
        } else {
          templateParams.ctrlnet_modules = "depth_midas, openpose_full";
          templateParams.pixel_perfects = pixel_perfects;
          templateParams.control_weights = control_weights;
          templateParams.control_modes = control_modes;
        }

        const mainTemplateId = position.template_name;
        const faceTemplate = position.group === groups.original_context_BG ? "8799" : faceTemplateChain;
        const combo = createCombo(fileIndex, faceTemplate, templateParams, mainTemplateId);
        combo.setGroup(position.group);
        combo.setExtra("group_1", position.group);
        combo.setExtra("group_2", seed);
        combo.setExtra("group_3", strength);

        results.push(combo);
      });
    });
  });

  return results;
}

function createCombo(fileIndex, faceTemplateChain, templateParamsArg, mainTemplateId) {
  const templateParams = {
    ...templateParamsArg,
    gender: "#0",
  };

  const steps = [
    {id: "gender_classifier"},
  ];

  const fileImages = [{src: "@" + fileIndex}];

  const faceTemplateChainArr = ("" + faceTemplateChain)
    .split(",")
    .map((i) => i.trim())
    .filter((i) => i.length > 0);

  faceTemplateChainArr.forEach((item, index) => {
    const step = {id: item};
    if (index === 0) {
      step.images = fileImages;
    }

    if (index === faceTemplateChainArr.length - 1) {
      step.setAsFile = "preprocessing";
    }

    steps.push(step);
  });

  const mainTemplateStep = {id: mainTemplateId, templateParams};
  if (steps.length === 1) {
    mainTemplateStep.images = fileImages;
  }

  steps.push(mainTemplateStep);

  let nameSegments = [];
  steps.forEach((step, index) => {
    if (index > 0) {
      nameSegments.push(step.id);
    }
  })

  const name = nameSegments.join("_") + ",seed:" + templateParams.seed + `,str:${templateParams.strength}`;
  const item = combo(steps);
  item.setExtra(Creative.EXTRA_NAME, name);

  return item;
}
